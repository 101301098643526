import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material/material.module";
import {TranslateModule} from "@ngx-translate/core";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {ReconnectSnackbarComponent} from './components/reconnect-snackbar/reconnect-snackbar.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {ErrorSnackbarComponent} from './components/error-snackbar/error-snackbar.component';
import {WarningSnackbarComponent} from './components/warning-snackbar/warning-snackbar.component';
import {SuccessSnackbarComponent} from './components/success-snackbar/success-snackbar.component';
import {RelativeDatePipe} from "@shared/pipes/relative-date.pipe";
import * as PlotlyJS from 'plotly.js-dist-min';
import {PlotlyModule} from 'angular-plotly.js';
import {DurationFormatPipe} from './pipes/duration-format.pipe';
import {DistanceFormatPipe} from './pipes/distance-format-.pipe';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {CountTotalNbOfRequiredComponentsPipe} from './pipes/count-total-nb-of-required-components.pipe';
import {NavigateBackDirective} from './directives/navigate-back.directive';
import {KeycloakAngularModule} from "keycloak-angular";
import {HasRolesDirective} from "@shared/directives/has-roles.directive";
import {PasswordInputComponent} from './components/password-input/password-input.component';
import {MapComponent} from './components/map/map.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {ScrollNearEndDirective} from "@shared/directives/scroll-near-end.directive";
import {MapValuesPipe} from "@shared/pipes/map-values.pipe";
import {SafeTranslatePipe} from './pipes/safe-translate.pipe';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

PlotlyModule.plotlyjs = PlotlyJS;


@NgModule({
  declarations: [
    ReconnectSnackbarComponent,
    FileUploadComponent,
    ErrorSnackbarComponent,
    WarningSnackbarComponent,
    SuccessSnackbarComponent,
    PasswordInputComponent,
    MapComponent,
    ConfirmationDialogComponent,

    HasRolesDirective,
    NavigateBackDirective,
    ScrollNearEndDirective,

    RelativeDatePipe,
    DurationFormatPipe,
    DistanceFormatPipe,
    MapValuesPipe,
    CountTotalNbOfRequiredComponentsPipe,
    SafeTranslatePipe,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    LeafletModule,
    NgxChartsModule,
    PlotlyModule,
    MatNativeDateModule,
    KeycloakAngularModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        LeafletModule,
        NgxChartsModule,
        PlotlyModule,
        MatNativeDateModule,

        HasRolesDirective,
        ScrollNearEndDirective,
        NavigateBackDirective,

        FileUploadComponent,
        PasswordInputComponent,
        MapComponent,
        ConfirmationDialogComponent,

        RelativeDatePipe,
        DurationFormatPipe,
        DistanceFormatPipe,
        MapValuesPipe,
        CountTotalNbOfRequiredComponentsPipe,
        SafeTranslatePipe,
    ]
})
export class SharedModule {
}
